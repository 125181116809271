import "../styles/Gallery.css";
import ClickOutside from "./ClickOutside";
import { useAppState } from "../utils/appState";
import { useState } from "react";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";

function Gallery({ images }) {
  const [, setAppState] = useAppState();
  const [selectedImage, setSelectedImage] = useState(0);
  // console.log(se);
  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false, hideMenu: false });
      }}
    >
      {(wrapperRef) => (
        <div ref={wrapperRef} className="gallery">
          {images.length > 1 && (
            <div
              className={
                "nav-btn prev " + (selectedImage <= 0 ? "disabled" : "")
              }
              onClick={() => {
                setSelectedImage(Math.max(0, selectedImage - 1));
              }}
            >
              <ArrowLeft />
            </div>
          )}

          <div className="image-container">
            <img src={images[selectedImage]} />
          </div>
          {images.length > 1 && (
            <div
              className={
                "nav-btn next " +
                (selectedImage >= images.length - 1 ? "disabled" : "")
              }
              onClick={() => {
                setSelectedImage(
                  Math.min(images.length - 1, selectedImage + 1)
                );
              }}
            >
              <ArrowRight />
            </div>
          )}
        </div>
      )}
    </ClickOutside>
  );
}

export default Gallery;
