import { animated, useSpring, config } from "react-spring";
import { useState } from "react";

function Chair({
  delay = 0,
  style = {},
  toStyle = {},
  className = "",
  onClick = () => {},
  easing = "molasses",
  index = 1,
}) {
  const [wobble, setWobble] = useState(false);

  const props = useSpring({
    to: { ...toStyle, opacity: 1 },
    from: { ...style, opacity: 0 },
    config: config[easing],
    delay,
    onRest: () => {
      setWobble(true);
    },
  });

  return (
    <animated.div
      style={props}
      className={className + " " + (wobble ? "wobble" + index : "")}
      onClick={onClick}
    >
      <img src={"/chair.png"} alt={""} />
    </animated.div>
  );
}

export default Chair;
