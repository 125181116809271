import { animated, useSpring, config } from "react-spring";

function FadeIn({
  children,
  delay = 0,
  style = {},
  toStyle = {},
  className = "",
  onClick = () => {},
  easing = "molasses",
}) {
  // const [show, set] = useState(true);
  // const transitions = useTransition(show, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  //   config: { tension: 220, friction: 120 },
  // });
  // return transitions.map(
  //   ({ item, key, props }) =>
  //     item && (
  //       <animated.div key={key} style={props}>
  //         {children}
  //       </animated.div>
  //     )
  // );

  const props = useSpring({
    to: { ...toStyle, opacity: 1 },
    from: { ...style, opacity: 0 },
    config: config[easing],
    delay,
  });
  return (
    <animated.div style={props} className={className} onClick={onClick}>
      {children}
    </animated.div>
  );
}

export default FadeIn;
