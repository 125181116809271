// import "./Home.css";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import Page from "../components/Page";
import useImageLoadState from "../utils/useImageLoadState";
import LoadingImages from "../components/LoadingImages";
import Img from "../components/Img";
import { useEffect } from "react";
import { useAppState } from "../utils/appState";

function Educational() {
  const [loading, loadImage] = useImageLoadState(2);
  const [, setAppState] = useAppState();

  useEffect(() => {
    setAppState({ fixedHeader: true });

    return () => {
      setAppState({ fixedHeader: false });
    };
  }, [setAppState]);
  return (
    <Page className="Category Corporate">
      {loading && <LoadingImages />}
      <div className="section">
        <div className="primary-image">
          <Img
            src={"/edu-1.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>

      <div className="blurb">
        <div className="subheading green-text secondary-text">EDUCATIONAL</div>
        <div className="h1 primary-text">We provide ergonomically designed</div>
        <div className="h1 primary-text">
          suitable school and college furniture
        </div>
        <div className="h1 primary-text">
          for classrooms and other spaces within
        </div>
        <div className="h1 primary-text">the establishment.</div>
        <div className="body-copy">
          <div className="secondary-text desktop-text">
            Given the end user in this space - our focus is on the form,
            ergonomics, strength and durablility of the
          </div>
          <div className="secondary-text desktop-text">
            products we deliver.
          </div>

          <p className="mobile-text secondary-text">
            Given the end user in this space - our focus is on the form,
            ergonomics, strength and durablility of the products we deliver.
          </p>
        </div>
      </div>

      <div className="section section-3">
        <div className="primary-image">
          <Img
            src={"/edu-2.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>

      <div className="section carousel-section">
        <Carousel
          images={[
            { url: "/edu-c-1.jpg" },
            { url: "/edu-c-2.jpg" },
            { url: "/edu-c-3.jpg" },
            { url: "/edu-c-4.jpg" },
          ]}
          interval={4000}
        />
      </div>

      <Footer />
    </Page>
  );
}

export default Educational;
