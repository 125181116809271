import { useState, useEffect } from "react";
import { useAppState } from "./appState";

function useImageLoadState(total) {
  const [loaded, setLoaded] = useState(0);
  const [, setAppState] = useAppState();
  const loading = loaded < total;
  // console.log(loaded);
  useEffect(() => {
    if (loading) {
      setAppState({ hideMenu: true, hideEnquire: true });
    } else {
      setAppState({ hideMenu: false, hideEnquire: false });
    }
  }, [loading, setAppState]);

  function loadImage(index) {
    setLoaded((loaded) => Math.min(loaded + 1, total));
  }

  return [loading, loadImage];
}

export default useImageLoadState;
