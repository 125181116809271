import "../styles/Service.css";
import ClickOutside from "./ClickOutside";
import { useAppState } from "../utils/appState";
import EnquireButton from "./EnquireButton";
import EnquiryForm from "./EnquiryForm";
import { ReactComponent as CloseIcon } from "../icons/close-icon.svg";

function Service({ title, description }) {
  const [, setAppState] = useAppState();
  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false, hideMenu: false });
      }}
    >
      {(wrapperRef) => (
        <div ref={wrapperRef} className="Service">
          <div
            className="modal-close hover-pointer"
            onClick={() => {
              setAppState({ modal: false, hideMenu: false });
            }}
          >
            <CloseIcon />
          </div>
          <div className="h3 heading">{title}</div>
          <div className="subheading">{description}</div>

          <EnquireButton
            onClick={() => {
              setAppState({ modal: <EnquiryForm /> });
            }}
          />
        </div>
      )}
    </ClickOutside>
  );
}

export default Service;
