const timelineItems = [
  {
    year: "1998",
    align: "top",
    text: [
      "Essentially Metal - Ravi Jouhari's brainchild was launched as a retail store pioneering contemporary designer metal furniture showcased in the heart of Bangalore City.",
      "EM - a result of a forward vertical integration from the manufacturing facility. The innovative designs were far ahead of what anyone had seen before in India at the time. ",
    ],
  },
  {
    year: "2000",
    align: "bottom",
    text: [
      "Apart from the instant recongnition gained from the retail customers - Essentially Metal soon began redefining modern interiors within the hospitality and corporate sectors. Customers such as ITC, Aviva, Taj, Cisco Systems amongst other notable industry leaders became repeat customers.",
    ],
  },
  {
    year: "2002",
    align: "top",
    text: [
      "With exponential growth in demand - Essentially Metal opened 8 stores across the country.",
    ],
  },
  {
    year: "2003",
    align: "bottom",
    text: [
      "Towards the end of the year the untimely demise of Ravi Jouhari meant the legacy was passed on to his wife and children to continue.",
    ],
  },
  {
    year: "2010",
    align: "top",
    text: [
      "The highly skilled and rich in experience - Nishu Jouhari was able to bring visions into reality with the customised furniture service. This attracted an array of domestic, hospitality and educational clientele who needed high quality bespoke furniture.",
    ],
  },
  {
    year: "2015",
    align: "bottom",
    text: [
      "Essentially Metal began exporting customized furniture to acclaimed Hotels in Europe and even received orders from ardent customers in the US.",
    ],
  },
  {
    year: "2018",
    align: "top",
    text: [
      "Having worked with international architects & designers - exposed to a plethora of materials EM has now gained a very strong reputation - one of experience and stature within the bespoke furniture industry.",
    ],
  },
];

export default timelineItems;
