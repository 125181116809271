// import "./Home.css";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import Page from "../components/Page";
import useImageLoadState from "../utils/useImageLoadState";
import LoadingImages from "../components/LoadingImages";
import Img from "../components/Img";
import { useEffect } from "react";
import { useAppState } from "../utils/appState";

function Hospitality() {
  const [loading, loadImage] = useImageLoadState(2);
  const [, setAppState] = useAppState();

  useEffect(() => {
    setAppState({ fixedHeader: true });

    return () => {
      setAppState({ fixedHeader: false });
    };
  }, [setAppState]);

  return (
    <Page className="Category Corporate">
      {loading && <LoadingImages />}
      <div className="section section-1">
        <div className="primary-image">
          <Img
            src={"/hosp-1.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>

      <div className="blurb  section-2">
        <div className="subheading green-text secondary-text">HOSPITALITY</div>
        <div className="h1 primary-text">
          At EM, we assure you of a responsible,
        </div>
        <div className="h1 primary-text">
          trustworthy partner to provide you a
        </div>
        <div className="h1 primary-text">
          customised furniture solution that
        </div>
        <div className="h1 primary-text">
          enables you to entrust us with all your
        </div>
        <div className="h1 primary-text">visions and aspirations.</div>
        <div className="body-copy">
          <div className="secondary-text desktop-text">
            We have dealt with leading Indian and international hospitality
            brands such as Taj Group of Hotels, Oberoi
          </div>
          <div className="secondary-text desktop-text">
            Hotels, Marriot Hotels amoungst various others you can find on our
            client list. These respected brands have
          </div>
          <div className="secondary-text desktop-text">
            further developed our capabilities over the years and we are
            grateful to our trusting client base.
          </div>

          <p className="mobile-text secondary-text">
            We have dealt with leading Indian and international hospitality
            brands such as Taj Group of Hotels, Oberoi Hotels, Marriot Hotels
            amoungst various others you can find on our client list. These
            respected brands have further developed our capabilities over the
            years and we are grateful to our trusting client base.
          </p>
        </div>
      </div>

      <div className="section section-3">
        <div className="primary-image">
          <Img
            src={"/hosp-2.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>

      <div className="section carousel-section">
        <Carousel
          images={[
            { url: "/hosp-c-1.jpg" },
            { url: "/hosp-c-2.jpg" },
            { url: "/hosp-c-3.jpg" },
            { url: "/hosp-c-4.jpg" },
          ]}
          interval={4000}
        />
      </div>

      <Footer />
    </Page>
  );
}

export default Hospitality;
