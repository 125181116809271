import "../styles/LoadingImages.css";
import { ReactComponent as Spinner } from "./spinner.svg";

function LoadingImages() {
  return (
    <div className="loading-images">
      <Spinner />
    </div>
  );
}

export default LoadingImages;
