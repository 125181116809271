import Page from "../components/Page";
import useImageLoadState from "../utils/useImageLoadState";
import { useAppState } from "../utils/appState";
import { useEffect } from "react";
import "../styles/Portfolio.css";
import Gallery from "../components/Gallery";

const clients = [
  {
    name: "Au Bon Pain",
    images: [
      "/portfolio/abp/1.JPG",
      "/portfolio/abp/2.JPG",
      "/portfolio/abp/3.JPG",
    ],
    cover: "/portfolio/abp/cover.jpg",
    coverStyle: { width: "100%", height: "auto" },
  },
  {
    name: "Bliss Chocolates",
    images: [
      "/portfolio/bliss/1.jpg",
      "/portfolio/bliss/2.jpg",
      "/portfolio/bliss/3.jpg",
    ],
    cover: "/portfolio/bliss/cover.jpg",
    coverStyle: { width: "100%", height: "auto" },
  },
  {
    name: "Clover Greens",
    images: [
      "/portfolio/clover/1.jpg",
      "/portfolio/clover/2.jpg",
      "/portfolio/clover/3.jpg",
    ],
    cover: "/portfolio/clover/cover.jpg",
  },
  {
    name: "Private Bungalow",
    images: [
      "/portfolio/domestic1/1.jpg",
      "/portfolio/domestic1/2.jpg",
      "/portfolio/domestic1/3.jpg",
      "/portfolio/domestic1/4.jpg",
      "/portfolio/domestic1/5.jpg",
    ],
    cover: "/portfolio/domestic1/cover.jpg",
  },
  {
    name: "Flower Box",
    images: ["/portfolio/flowerbox/1.jpg"],
    cover: "/portfolio/flowerbox/cover.jpg",
    coverStyle: { width: "100%", height: "auto" },
  },
  {
    name: "The Hangout",
    images: ["/portfolio/hangout/1.jpg", "/portfolio/hangout/2.jpg"],
    cover: "/portfolio/hangout/cover.jpg",
  },
  {
    name: "Hopshaus Brewery",
    images: [
      "/portfolio/hopshaus/1.jpg",
      "/portfolio/hopshaus/2.jpg",
      "/portfolio/hopshaus/3.jpg",
      "/portfolio/hopshaus/4.jpg",
      "/portfolio/hopshaus/5.jpg",
    ],
    cover: "/portfolio/hopshaus/cover.jpg",
  },
  {
    name: "Lambretta, Goa",
    images: ["/portfolio/lambretta/1.JPG"],
    cover: "/portfolio/lambretta/cover.JPG",
  },
  {
    name: "Laughing Llama Gastropub",
    images: [
      "/portfolio/laughingllama/1.jpg",
      "/portfolio/laughingllama/2.jpg",
      "/portfolio/laughingllama/3.jpg",
    ],
    cover: "/portfolio/laughingllama/cover.jpg",
  },
  {
    name: "Mirrors & Within",
    images: ["/portfolio/mw/1.jpg", "/portfolio/mw/2.jpg"],
    cover: "/portfolio/mw/cover.jpg",
    coverStyle: { width: "100%", height: "auto" },
  },
  {
    name: "MISU",
    images: ["/portfolio/misu_ind/1.jpg", "/portfolio/misu_ind/2.jpg"],
    cover: "/portfolio/misu_ind/cover.jpg",
    coverStyle: { width: "100%", height: "auto" },
  },
  {
    name: "Pasta Street",
    images: ["/portfolio/pastastreet/1.jpg", "/portfolio/pastastreet/2.jpg"],
    cover: "/portfolio/pastastreet/cover.jpg",
  },
  {
    name: "MISU 2",
    images: [
      "/portfolio/misu2/1.jpg",
      "/portfolio/misu2/2.jpg",
      "/portfolio/misu2/3.jpg",
      "/portfolio/misu2/4.jpg",
    ],
    cover: "/portfolio/misu2/cover.jpg",
  },
  {
    name: "Pasta Street Whitefield",
    images: [
      "/portfolio/pastastreet2/1.jpg",
      "/portfolio/pastastreet2/2.jpg",
      "/portfolio/pastastreet2/3.jpg",
      "/portfolio/pastastreet2/4.jpg",
    ],
    cover: "/portfolio/pastastreet2/cover.jpg",
  },
  {
    name: "Sterling Ascentia Villa",
    images: [
      "/portfolio/sterling-ascentia/1.jpg",
      "/portfolio/sterling-ascentia/2.jpg",
      "/portfolio/sterling-ascentia/3.jpg",
      ,
      "/portfolio/sterling-ascentia/4.jpg",
    ],
    cover: "/portfolio/sterling-ascentia/cover.jpg",
  },
  {
    name: "Sterling Infinea Villa",
    images: [
      "/portfolio/sterling-infinea/1.jpg",
      "/portfolio/sterling-infinea/2.jpg",
      "/portfolio/sterling-infinea/3.jpg",
      "/portfolio/sterling-infinea/4.jpg",
    ],
    cover: "/portfolio/sterling-infinea/cover.jpg",
  },
  {
    name: "Sterling Joy on the Banks",
    images: [
      "/portfolio/sterling-joy/1.jpg",
      "/portfolio/sterling-joy/2.jpg",
      "/portfolio/sterling-joy/3.jpg",
      "/portfolio/sterling-joy/4.jpg",
    ],
    cover: "/portfolio/sterling-joy/cover.jpg",
  },
  {
    name: "Toscano",
    images: [
      "/portfolio/toscano/1.jpg",
      "/portfolio/toscano/2.jpg",
      "/portfolio/toscano/3.jpg",
    ],
    cover: "/portfolio/toscano/cover.jpg",
  },
  {
    name: "The Palms Spa",
    images: ["/portfolio/palms/1.jpg", "/portfolio/palms/2.jpg"],
    cover: "/portfolio/palms/cover.jpg",
  },
];

function Portfolio() {
  const [loading, loadImage] = useImageLoadState(3);
  const [, setAppState] = useAppState();

  useEffect(() => {
    setAppState({ fixedHeader: true });

    return () => {
      setAppState({ fixedHeader: false });
    };
  }, [setAppState]);

  return (
    <Page className="Portfolio">
      <div className="section">
        <div className="h1">EM Portfolio</div>

        <div className="portfolio-list">
          {clients.map((c, i) => (
            <div
              className="portfolio-list-item-wrapper"
              style={{ paddingLeft: !(i % 4) ? 0 : 8, paddingRight: 8 }}
            >
              <div
                className="portfolio-list-item hover-pointer"
                onClick={() => {
                  setAppState({
                    modal: <Gallery images={c.images} />,
                    hideMenu: true,
                  });
                }}
              >
                <div className="image">
                  {c.cover && (
                    <img style={{ ...(c.coverStyle || {}) }} src={c.cover} />
                  )}
                </div>
                <div className="label subheading">{c.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
}

export default Portfolio;
