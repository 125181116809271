import "../styles/Home.css";
import Footer from "../components/Footer";
import { ReactComponent as InstagramIcon } from "../icons/instagram.svg";
import FadeIn from "../components/FadeIn";
import Chair from "../components/Chair";
import ShowOnScreen from "../components/ShowOnScreen";
import Link from "../components/Link";
import Page from "../components/Page";
import LoadingImages from "../components/LoadingImages";
import Service from "../components/Service";
import { useAppState } from "../utils/appState";
import useImageLoadState from "../utils/useImageLoadState";
import Img from "../components/Img";

const services = [
  {
    title: "customisation",
    delay: 0,
    description:
      "Any product, any design, any material - think limitless. Our experience of 25+ years will assist getting your furniture vision to reality.",
  },
  {
    title: "sourcing",
    delay: 0,
    description:
      "Adamant on sourcing a product from a particular region or overseas? We will identify vendors, overlook quality and logistics to ensure you get your product delivered right to your doorstep.",
  },
  {
    title: "design consultation",
    delay: 0,
    description:
      "Whether you are launching a chain of restaurants/retail stores or even a product designer at heart and don't know where to start - we will help you design the product from your brief through our in depth bespoke product design process.",
  },
  {
    title: "maintenance",
    delay: 0,
    description:
      "From polish colour touch ups, reupholstery of sofas to repair of damaged furniture - we are here to help you bring those pieces back to life!",
  },
];

function Home({ setScroll }) {
  const [, setAppState] = useAppState();
  const [loading, loadImage] = useImageLoadState(9);
  // const loading = true;
  // console.log(loaded);

  return (
    <Page className="Home">
      {loading && <LoadingImages />}
      <div className="section section-1">
        <ShowOnScreen loading={loading} className="meta">
          <FadeIn delay={100}>
            <div className="primary-text h1">Welcome to the World of</div>
          </FadeIn>
          <FadeIn delay={100}>
            <div className="primary-text h1">Essentially Metal</div>
          </FadeIn>

          <FadeIn delay={200}>
            <div className="secondary-text subheading">
              DELIVERING AWARD WINNING DESIGNS SINCE 1998
            </div>
          </FadeIn>
        </ShowOnScreen>

        <ShowOnScreen className="options">
          <FadeIn delay={350} className="instagram-link">
            <a
              className="social-link hover-pointer"
              href="https://www.instagram.com/essentiallymetal/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
          </FadeIn>
        </ShowOnScreen>

        <Img
          src={"/home-1.jpg"}
          className="bg-img"
          onLoad={() => {
            loadImage();
          }}
          loading={loading}
        />
      </div>

      <div className="section section-2">
        <div className="image show-mobile">
          <Img
            src={"/home-2.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>

        <ShowOnScreen className="text">
          <FadeIn
            delay={0}
            style={{ transform: "translateX(-25px)" }}
            toStyle={{ transform: "translateX(0px)" }}
          >
            <div className="subheading secondary-text caption">
              DESIGN & CUSTOMIZED EXECUTION
            </div>
          </FadeIn>

          <FadeIn
            delay={50}
            style={{ transform: "translateX(-25px)" }}
            toStyle={{ transform: "translateX(0px)" }}
          >
            <div className="h1 primary-text">Let us build a story</div>
            <div className="h1 primary-text">for You</div>
          </FadeIn>

          <FadeIn
            delay={100}
            style={{ transform: "translateX(-25px)" }}
            toStyle={{ transform: "translateX(0px)" }}
          >
            <Link className="subheading secondary-text link" to="/about">
              Read About us & What we do
            </Link>
          </FadeIn>
        </ShowOnScreen>

        <div className="image hide-mobile">
          <FadeIn
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              overflow: "hidden",
            }}
          >
            <Img
              src={"/home-2.jpg"}
              onLoad={() => {
                loadImage("1");
              }}
              loading={loading}
            />
          </FadeIn>
        </div>
      </div>

      <div className="section section-3">
        <ShowOnScreen className="text">
          <FadeIn delay={0}>
            <div
              className="mixed-text line-1"
              style={{
                fontSize: 60,
                letterSpacing: "12px",
                marginRight: -10,
                height: 60,
              }}
            >
              <span className="tiempos" style={{ lineHeight: "60.4px" }}>
                SINCE
              </span>{" "}
              <span
                className="tiempos green-text"
                style={{ lineHeight: "60px" }}
              >
                1998
              </span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-2"
              style={{ letterSpacing: "1px", lineHeight: "32px" }}
            >
              <span className="tiempos">Serviced over 6000 clients</span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div className="mixed-text  line-3" style={{ fontSize: 56 }}>
              <span className="sang-bleu" style={{ lineHeight: "56px" }}>
                Delivered over
              </span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-4"
              style={{ fontSize: 40, lineHeight: "40px" }}
            >
              <span className="tiempos">
                <span className="green-text">25,000</span> unique designs
              </span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-5"
              style={{ fontSize: 43, lineHeight: "43px" }}
            >
              <span className="sang-bleu">
                Reached <span className="green-text">70+ cities</span>
              </span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-6"
              style={{
                letterSpacing: "23.5px",
                lineHeight: "32px",
                marginRight: -20,
              }}
            >
              <span className="tiempos">WORLDWIDE</span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-7"
              style={{
                fontSize: 48,
                lineHeight: "48px",
                letterSpacing: "0.8px",
              }}
            >
              <span className="tiempos">
                USED <span className="green-text">38 UNIQUE</span>
              </span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-8"
              style={{ fontSize: 60, lineHeight: "60px" }}
            >
              <span className="sang-bleu">Materials and</span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-9"
              style={{ fontSize: 33, lineHeight: "33px" }}
            >
              <span className="tiempos-italic">
                always believed in the motto:
              </span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-10"
              style={{ fontSize: 22, lineHeight: "22px", marginRight: -1 }}
            >
              <span className="sang-bleu green-text">
                At EM we initiate a relationship to be
              </span>
            </div>
          </FadeIn>

          <FadeIn delay={0}>
            <div
              className="mixed-text line-11"
              style={{ fontSize: 38, lineHeight: "38px" }}
            >
              <span className="tiempos green-text">FOREVER SUSTAINED</span>
            </div>
          </FadeIn>
        </ShowOnScreen>

        <Img
          src={"/home-3.jpg"}
          className="bg-img"
          onLoad={() => {
            loadImage("1");
          }}
          loading={loading}
        />
      </div>

      <div className="section section-4">
        <div className="main-image">
          <Img
            src={"/home-4.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
        <div className="meta">
          <ShowOnScreen className="wrapper">
            <FadeIn
              delay={0}
              style={{ transform: "translateX(25px)" }}
              toStyle={{ transform: "translateX(0px)" }}
            >
              <div className="subheading secondary-text caption green-text">
                THE CLOVER CHAIR
              </div>
            </FadeIn>
            <FadeIn
              delay={100}
              style={{ transform: "translateX(25px)" }}
              toStyle={{ transform: "translateX(0px)" }}
            >
              <div className="h1 primary-text green-text">
                Take a stroll through the
              </div>
            </FadeIn>

            <FadeIn
              delay={200}
              style={{ transform: "translateX(25px)" }}
              toStyle={{ transform: "translateX(0px)" }}
            >
              <div className="h1 primary-text green-text">
                world of bespoke designs. The
              </div>
            </FadeIn>
            <FadeIn
              delay={300}
              style={{ transform: "translateX(25px)" }}
              toStyle={{ transform: "translateX(0px)" }}
            >
              <div className="h1 primary-text green-text">
                possibilities are endless.
              </div>
            </FadeIn>
            <FadeIn
              delay={400}
              style={{ transform: "translateX(25px)" }}
              toStyle={{ transform: "translateX(0px)" }}
            >
              <Link
                to={"/portfolio"}
                className="subheading secondary-text link"
              >
                Get inspired through our Portfolio
              </Link>
            </FadeIn>
            <div className="image-row">
              <Chair
                delay={0}
                style={{ transform: "translateX(1000px) rotateZ(0deg)" }}
                toStyle={{ transform: "translateX(0px) rotateZ(0deg)" }}
                className="image"
                easing="slow"
                index={1}
              />

              <Chair
                delay={100}
                style={{ transform: "translateX(1000px) rotateZ(0deg)" }}
                toStyle={{ transform: "translateX(0px) rotateZ(15deg)" }}
                className="image"
                easing="slow"
                index={2}
              />

              <Chair
                delay={200}
                style={{ transform: "translateX(1000px) rotateZ(0deg)" }}
                toStyle={{ transform: "translateX(0px) rotateZ(200deg)" }}
                className="image hide-mobile"
                easing="slow"
                index={3}
              />

              <Chair
                delay={300}
                style={{ transform: "translateX(1000px) rotateZ(0deg)" }}
                toStyle={{ transform: "translateX(0px) rotateZ(0deg)" }}
                className="image hide-mobile"
                easing="slow"
                index={4}
              />

              {/*<FadeIn
                delay={0}
                style={{ transform: "translateX(1000px)" }}
                toStyle={{ transform: "translateX(0px)" }}
                className="image"
                easing="slow"
              >
                <img src={"/chair.png"} alt={""} />
              </FadeIn>
              <FadeIn
                delay={100}
                style={{ transform: "translateX(1000px) rotateZ(0deg)" }}
                toStyle={{ transform: "translateX(0px) rotateZ(15deg)" }}
                className="image"
                easing="slow"
              >
                <img src={"/chair.png"} alt={""} />
              </FadeIn>
              <FadeIn
                delay={200}
                style={{ transform: "translateX(1000px) rotateZ(0deg)" }}
                toStyle={{ transform: "translateX(0px) rotateZ(200deg)" }}
                className="image"
                easing="slow"
              >
                <img src={"/chair.png"} alt={""} />
              </FadeIn>
              <FadeIn
                delay={300}
                style={{ transform: "translateX(1000px)" }}
                toStyle={{ transform: "translateX(0px)" }}
                className="image"
                easing="slow"
              >
                <img
                  src={"/chair.png"}
                  style={{ transform: "rotateY(180deg)" }}
                  alt={""}
                />
              </FadeIn>*/}
            </div>
          </ShowOnScreen>
        </div>
      </div>
      <div className="section section-5">
        <ShowOnScreen className="wrapper">
          <div className="h1 primary-text green-text">
            How Can Our Designers Help You?
          </div>

          <div className="subheading secondary-text">
            Our four core offerings are presonally overlooked by an expert and
            you will have a one point
          </div>

          <div className="subheading secondary-text">
            contact from initial contact to final delivery of the product /
            service.
          </div>

          <div className="offerings">
            {services.map((s) => (
              <FadeIn
                key={s.title}
                delay={s.delay}
                className="offering hover-pointer"
                onClick={() => {
                  setAppState({
                    modal: (
                      <Service
                        title={s.title.toUpperCase()}
                        description={s.description}
                      />
                    ),
                    hideMenu: true,
                  });
                }}
              >
                <div className="offering-icon">
                  <img
                    src={`/${s.title}.svg`}
                    style={{ height: 100 }}
                    alt={""}
                  />
                </div>
                <div className="offering-desc">{s.title.toUpperCase()}</div>
              </FadeIn>
            ))}
          </div>
        </ShowOnScreen>
      </div>

      <div className="section section-6">
        <div className="blocks">
          <Link className="block" to="/hospitality">
            <div delay={50} className="block-image">
              <Img
                src={"/home-5.jpg"}
                onLoad={() => {
                  loadImage("1");
                }}
                loading={loading}
              />
            </div>
            <div className="subheading block-desc">HOSPITALITY</div>
          </Link>

          <Link className="block" to="/educational">
            <div delay={70} className="block-image">
              <Img
                src={"/home-6.jpg"}
                onLoad={() => {
                  loadImage("1");
                }}
                loading={loading}
              />
            </div>
            <div className="subheading block-desc">EDUCATIONAL</div>
          </Link>

          <Link className="block" to="/corporate">
            <div delay={90} className="block-image">
              <Img
                src={"/home-7.jpg"}
                onLoad={() => {
                  loadImage("1");
                }}
                loading={loading}
              />
            </div>
            <div className="subheading block-desc">CORPORATE</div>
          </Link>

          <Link className="block" to="/residential">
            <div delay={90} className="block-image">
              <Img
                src={"/home-8.jpg"}
                onLoad={() => {
                  loadImage("1");
                }}
                loading={loading}
              />
            </div>
            <div className="subheading block-desc">RESIDENTIAL</div>
          </Link>
        </div>
      </div>

      <div className="section section-7">
        <div className="meta">
          <ShowOnScreen className="wrapper">
            <FadeIn
              delay={0}
              style={{ transform: "translateX(-25px)" }}
              toStyle={{ transform: "translateX(0px)" }}
              className="subheading green-text caption secondary-text"
            >
              NISHU JOUHARI
            </FadeIn>
            <FadeIn
              delay={100}
              style={{ transform: "translateX(-25px)" }}
              toStyle={{ transform: "translateX(0px)" }}
              className="h1 headline"
            >
              <div className="h1 primary-text">Get to know your</div>
              <div className="h1 primary-text">fabulous highly</div>
              <div className="h1 primary-text">experienced Principal</div>
              <div className="h1 primary-text">Design and Furniture</div>
              <div className="h1 primary-text">Expert.</div>
            </FadeIn>

            <FadeIn
              delay={200}
              style={{ transform: "translateX(-25px)" }}
              toStyle={{ transform: "translateX(0px)" }}
            >
              <div className="body-copy secondary-text desktop-text">
                Mrs. Nishu Jouhari has had over 25 years of experience within
              </div>

              <div className="body-copy secondary-text  desktop-text">
                the interior and product design field spearheads the in-house
              </div>
              <div className="body-copy secondary-text  desktop-text">
                design service offered by Essentially Metal. With prior
              </div>
              <div className="body-copy secondary-text  desktop-text">
                experience in the garment export industry, high quality and
              </div>
              <div className="body-copy secondary-text  desktop-text">
                innovative design comes naturally to her and is the primary
              </div>
              <div className="body-copy secondary-text  desktop-text">
                language spoken by the EM Team. Regularly working with
              </div>
              <div className="body-copy secondary-text  desktop-text">
                award winning architects and designers from all parts of the
              </div>
              <div className="body-copy secondary-text  desktop-text">
                globe has re-established that Jouhari's passion is recognised in
              </div>
              <div className="body-copy secondary-text  desktop-text">
                the world class designs and products produced from the
              </div>
              <div className="body-copy secondary-text  desktop-text">
                house of Essentially Metal.
              </div>
            </FadeIn>

            <p className="mobile-text body-copy secondary-text">
              Mrs. Nishu Jouhari has had over 25 years of experience within the
              interior and product design field spearheads the in-house design
              service offered by Essentially Metal. With prior experience in the
              garment export industry, high quality and innovative design comes
              naturally to her and is the primary language spoken by the EM
              Team. Regularly working with award winning architects and
              designers from all parts of the globe has re-established that
              Jouhari's passion is recognised in the world class designs and
              products produced from the house of Essentially Metal.
            </p>
          </ShowOnScreen>
        </div>

        <div className="image">
          <Img
            src={"/home-9.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>

      <Footer />
    </Page>
  );
}

export default Home;
