import "../styles/EnquireButton.css";
import { useAppState } from "../utils/appState";

function EnquireButton({ onClick, className, style }) {
  const [{ hideEnquire }] = useAppState();
  return hideEnquire ? null : (
    <div
      className={"subheading hover-pointer Enquire-button " + className}
      onClick={onClick}
      style={style}
    >
      ENQUIRE
    </div>
  );
}

export default EnquireButton;
