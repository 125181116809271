import { useState } from "react";
import "../styles/Menu.css";
import ClickOutside from "./ClickOutside";

import { useAppState } from "../utils/appState";
import { useHistory } from "react-router-dom";
import Contact from "./Contact";
import EnquiryForm from "./EnquiryForm";

function Menu({ style }) {
  const [{ modal, hideMenu }, setAppState] = useAppState();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const navigate = (to) => {
    // setAppState({ headerScroll: 0, footerScroll: 0 });
    history.push(to);
    setOpen(false);
    setAppState({ modal: false });
  };
  return hideMenu ? null : (
    <div className="Menu" style={style}>
      <div
        className={"Menu-button hover-pointer " + (open ? "disable" : "")}
        onClick={() => {
          if (!modal) {
            setAppState({ modal: true });
          }
          setOpen(!open);
        }}
      >
        Menu
      </div>
      <ClickOutside
        onClickOutside={() => {
          setOpen(false);
          setAppState({ modal: false });
        }}
      >
        {(wrapperRef) => (
          <div
            ref={wrapperRef}
            className={"Menu-popup " + (open ? "open" : "")}
          >
            <div className="Menu-group">
              <div
                className="heading hover-pointer"
                onClick={() => {
                  navigate("/about");
                }}
              >
                ABOUT US
              </div>
            </div>
            <div className="Menu-group">
              <div className="heading no-event" style={{ marginBottom: 8 }}>
                INDUSTRIES
              </div>
              <div
                className="sub-heading hover-pointer"
                onClick={() => {
                  navigate("/hospitality");
                }}
              >
                • HOSPITALITY •
              </div>
              <div
                className="sub-heading hover-pointer"
                onClick={() => {
                  navigate("/educational");
                }}
              >
                • EDUCATIONAL •
              </div>
              <div
                className="sub-heading hover-pointer"
                onClick={() => {
                  navigate("/corporate");
                }}
              >
                • CORPORATE •
              </div>
              <div
                className="sub-heading hover-pointer"
                onClick={() => {
                  navigate("/residential");
                }}
              >
                • DOMESTIC •
              </div>
            </div>
            <div
              className="Menu-group"
              onClick={() => {
                setOpen(false);
                setAppState({ modal: <EnquiryForm />, hideMenu: true });
              }}
            >
              <div className="heading hover-pointer">DESIGN ENQUIRY</div>
            </div>
            <div
              className="Menu-group"
              onClick={() => {
                navigate("/portfolio");
              }}
            >
              <div className="heading hover-pointer">THE EM PORTFOLIO</div>
            </div>
            <div
              className="Menu-group"
              onClick={() => {
                setOpen(false);
                setAppState({ modal: <Contact />, hideMenu: true });
              }}
            >
              <div className="heading hover-pointer">CONTACT</div>
            </div>
          </div>
        )}
      </ClickOutside>
    </div>
  );
}

export default Menu;
