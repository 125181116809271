import { useState } from "react";

function useMutation(endpoint, { onSuccess = () => {}, onError = () => {} }) {
  const [loading, setLoading] = useState(false);
  const [error] = useState(false);

  const mutation = async (data = {}) => {
    setLoading(true);
    const vars = JSON.stringify(data);
    console.log("Vars:", vars);
    const res = await fetch(endpoint, {
      method: "POST",
      body: vars,
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const o = res.status;

    if (o === 200) {
      const json = await res.json();
      if (json.error) {
        onError(json.error);
      } else {
        onSuccess(json);
      }
    }

    return o;
  };

  return [mutation, { loading, error }];
}

export default useMutation;

// const mutation = async (data = {}) => {
//   setLoading(true);
//   console.log("Vars:", data);
//   const response = await fetch(endpoint, {
//     method: "POST",
//     mode: "no-cors",
//     body: JSON.stringify(data),
//   });
//   setLoading(false);
//
//   return response.json();
// };
