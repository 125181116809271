import { animated, useSpring, config } from "react-spring";

function Img({ src, loading, className, onLoad = () => {}, style = {} }) {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: loading ? 0 : 1 },
    config: config.slow,
  });
  return (
    <animated.img
      src={src}
      className={className + (loading ? " hide" : "")}
      style={{ style, ...props }}
      onLoad={onLoad}
      alt={""}
    />
  );
}

export default Img;
