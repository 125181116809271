function handleScroll(scrollRef, setAppState) {
  const viewportHeight =
    scrollRef && scrollRef.current && scrollRef.current.offsetHeight;
  const scrollHeight =
    scrollRef && scrollRef.current && scrollRef.current.scrollHeight;
  const breakpoint = 0.6 * viewportHeight;
  const percentageScrolled = Math.min(
    scrollRef.current.scrollTop / breakpoint,
    1
  );

  const scrollRemaining =
    scrollHeight - scrollRef.current.scrollTop - viewportHeight;

  setAppState({
    headerScroll: percentageScrolled,
    footerScroll: scrollRemaining < 147 ? 147 - scrollRemaining : 0,
  });
}

export default handleScroll;
