import { useRef } from "react";
import useOnScreen from "../utils/useOnScreen";

function ShowOnScreen({ children, className, loading }) {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  return (
    <div ref={ref} className={className}>
      {isVisible && !loading && children}
    </div>
  );
}

export default ShowOnScreen;
