import { useState } from "react";
import "../styles/EnquiryForm.css";
import ClickOutside from "./ClickOutside";
import { useAppState } from "../utils/appState";
import useMutation from "../utils/useMutation";
import { ReactComponent as Loading } from "../components/spinner-black.svg";

const enquiryTypes = [
  "Architect",
  "Interior Designer",
  "End Client",
  "Exporter",
];

const requirements = [
  "Design Services",
  "Loose Furniture",
  "Fixed Furniture",
  "Turnkey",
  "All of the above",
];

function EnquiryFormOne({ setIndex, formState, update }) {
  const [error, setError] = useState("");
  return (
    <div className="form">
      <div className="input-group placeholder-label">
        <input
          type="text"
          className="text-input"
          value={formState.firstname}
          onChange={(e) => {
            update({ firstname: e.target.value });
          }}
          id="firstname"
          name="firstname"
          required="required"
        />

        <label for="firstname">First Name</label>
      </div>
      <div className="input-group  placeholder-label">
        <input
          type="text"
          className="text-input"
          value={formState.lastname}
          onChange={(e) => {
            update({ lastname: e.target.value });
          }}
          id="lastname"
          name="lastname"
          required="required"
        />
        <label for="lastname">Last Name</label>
      </div>
      <div className="input-group placeholder-label">
        <input
          type="text"
          className="text-input"
          value={formState.organization}
          onChange={(e) => {
            update({ organization: e.target.value });
          }}
          id="organization"
          name="organization"
          required="required"
        />
        <label for="organization">Organization</label>
      </div>
      <div className="input-group placeholder-label">
        <input
          type="text"
          className="text-input"
          value={formState.phone}
          onChange={(e) => {
            update({ phone: e.target.value });
          }}
          id="phone"
          name="phone"
          required="required"
        />
        <label for="phone">Phone Number</label>
      </div>
      <div className="input-group placeholder-label">
        <input
          type="text"
          className="text-input"
          value={formState.email}
          onChange={(e) => {
            update({ email: e.target.value });
          }}
          id="email"
          name="email"
          required="required"
        />
        <label for="email">Email</label>
      </div>

      <div className="input-group" style={{ marginTop: 24, marginBottom: 24 }}>
        <div className="subheading label">Enquiry Type:</div>
        <div className="row">
          {enquiryTypes.map((et) => (
            <div className="radio-group">
              <input
                type="radio"
                id={et}
                name="type"
                value={et}
                checked={formState.enquiryType === et}
                onChange={(e) => {
                  console.log(e.target.value);
                  update({ enquiryType: e.target.value });
                }}
              />
              <label className="body-copy" for={et}>
                {et}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="error">{error}</div>

      <div className="options">
        <button
          className="primary hover-pointer"
          onClick={() => {
            setError("");

            if (!formState.firstname) {
              setError("First name is required.");
              return;
            }

            if (!formState.lastname) {
              setError("Last name is required.");
              return;
            }

            if (!formState.organization) {
              setError("Organization is required.");
              return;
            }

            if (!formState.phone) {
              setError("Phone Number is required.");
              return;
            }

            if (!formState.email) {
              setError("Email is required.");
              return;
            }

            setIndex(1);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}

function EnquiryFormTwo({ setIndex, formState, update }) {
  const [error, setError] = useState("");
  const [enquire, { loading }] = useMutation(
    "https://sea-lion-app-zmtzv.ondigitalocean.app/api/enquire",
    {
      onSuccess: (data) => {
        console.log("Response Data:", data);
        setIndex(2);
      },
      onError: (message) => {
        console.log("Error:", message);
        setError(message);
      },
    }
  );

  return (
    <div className="form">
      <div className="input-group">
        <div className="subheading label">
          Project Client:<span className="req">*</span>
        </div>
        <input
          type="text"
          className="text-input"
          placeholder=""
          value={formState.projectClient}
          onChange={(e) => {
            update({ projectClient: e.target.value });
          }}
        />
      </div>
      <div className="input-group" style={{ marginTop: 32 }}>
        <div className="subheading label">
          Project Timeline:<span className="req">*</span>
        </div>
        <div className="row">
          <div className="subheading label">Start Date</div>
          <input
            type="date"
            className="datetime-input"
            placeholder=""
            style={{ marginLeft: "auto", marginBottom: 16 }}
            value={formState.startDate}
            onChange={(e) => {
              update({ startDate: e.target.value });
            }}
          />
        </div>

        <div className="row">
          <div className="subheading label">Handover Date</div>
          <input
            type="date"
            className="datetime-input"
            placeholder=""
            style={{ marginLeft: "auto" }}
            value={formState.endDate}
            onChange={(e) => {
              update({ endDate: e.target.value });
            }}
          />
        </div>
      </div>

      <div className="input-group" style={{ marginTop: 32 }}>
        <div className="subheading label">
          Requirement:<span className="req">*</span>
        </div>
        <div className="row">
          {requirements.map((et) => (
            <div className="radio-group">
              <input
                type="radio"
                id={et}
                name="req"
                value={et}
                checked={formState.requirement === et}
                onChange={(e) => {
                  update({ requirement: e.target.value });
                }}
              />
              <label className="body-copy" for={et}>
                {et}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="input-group" style={{ marginTop: 32 }}>
        <div className="subheading label">Have you worked with EM before:</div>
        <div className="row">
          <div className="radio-group">
            <input
              type="radio"
              id={"Yes"}
              name="work"
              value={"Yes"}
              checked={formState.previousEngagement === "Yes"}
              onChange={(e) => {
                update({ previousEngagement: e.target.value });
              }}
            />
            <label className="body-copy" for={"Yes"}>
              Yes
            </label>
          </div>

          <div className="radio-group">
            <input
              type="radio"
              id={"No"}
              name="work"
              value={"No"}
              checked={formState.previousEngagement === "No"}
              onChange={(e) => {
                update({
                  previousEngagement: e.target.value,
                  previousInteraction: "",
                });
              }}
            />
            <label className="body-copy" for={"No"}>
              No
            </label>
          </div>
        </div>
      </div>

      {formState.previousEngagement === "Yes" && (
        <div className="input-group" style={{ marginTop: 32 }}>
          <div className="subheading label">Describe your interaction:</div>
          <textarea
            className="textarea-input"
            value={formState.previousInteraction}
            onChange={(e) => {
              update({ previousInteraction: e.target.value });
            }}
          />
        </div>
      )}

      <div className="error">{error}</div>

      <div className="options">
        <button
          onClick={() => {
            if (loading) {
              return;
            }

            if (!formState.projectClient) {
              setError("Project Client is required.");
              return;
            }

            if (!formState.startDate || !formState.endDate) {
              setError("Project Timeline is required.");
              return;
            }

            if (!formState.requirement) {
              setError("Requirement is required.");
              return;
            }

            enquire({
              firstname: formState.firstname,
              lastname: formState.lastname,
              organization: formState.organization,
              phone: formState.phone,
              email: formState.email,
              enquiryType: formState.enquiryType,
              projectClient: formState.projectClient,
              startDate: formState.startDate,
              endDate: formState.endDate,
              requirement: formState.requirement,
              previousEngagement: formState.previousEngagement,
              previousInteraction: formState.previousInteraction,
            });
          }}
          className="primary hover-pointer"
        >
          Submit
        </button>
        <button
          onClick={() => {
            setIndex(0);
          }}
          className="secondary hover-pointer"
        >
          Previous
        </button>

        {loading && <Loading />}
      </div>
    </div>
  );
}
//
// function Submitted() {
//   return (
//     <div className="form">
//       <div className="options">
//         <button className="primary hover-pointer">Close</button>
//       </div>
//     </div>
//   );
// }

function EnquiryForm() {
  const [index, setIndex] = useState(0);
  const [, setAppState] = useAppState();
  const [formState, setFormState] = useState({
    firstname: "",
    lastname: "",
    organization: "",
    phone: "",
    email: "",
    enquiryType: "",
    projectClient: "",
    startDate: new Date().toISOString().substr(0, 10),
    endDate: new Date().toISOString().substr(0, 10),
    requirement: "",
    previousEngagement: "",
    previousInteraction: "",
  });

  console.log(formState);

  const update = (state) => {
    setFormState({ ...formState, ...state });
  };

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false, hideMenu: false });
      }}
    >
      {(wrapperRef) => (
        <div className="EnquiryForm-wrapper">
          <div ref={wrapperRef} className="EnquiryForm">
            <div className="h4 title">DESIGN ENQUIRY</div>

            {index === 2 ? (
              <div className="subheading caption">
                <div>
                  Thank you for your enquiry. We will get back to you at the
                  earliest!
                </div>
              </div>
            ) : (
              <div className="subheading caption">
                <div>Fill the form as the first step towards letting us</div>
                <div>support you with your requirement.</div>
              </div>
            )}

            <div className="form-container">
              {!index && (
                <EnquiryFormOne
                  setIndex={setIndex}
                  formState={formState}
                  update={update}
                />
              )}

              {index === 1 && (
                <EnquiryFormTwo
                  setIndex={setIndex}
                  formState={formState}
                  update={update}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </ClickOutside>
  );
}

export default EnquiryForm;
