import "../styles/Category.css";
import Footer from "../components/Footer";
import Page from "../components/Page";
import useImageLoadState from "../utils/useImageLoadState";
import LoadingImages from "../components/LoadingImages";
import Img from "../components/Img";
import { useEffect } from "react";
import { useAppState } from "../utils/appState";

function Corporate() {
  const [loading, loadImage] = useImageLoadState(2);
  const [, setAppState] = useAppState();

  useEffect(() => {
    setAppState({ fixedHeader: true });

    return () => {
      setAppState({ fixedHeader: false });
    };
  }, [setAppState]);
  return (
    <Page className="Category Corporate">
      {loading && <LoadingImages />}
      <div className="section section-1">
        <div className="primary-image">
          <Img
            src={"/corp-1.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>

      <div className="blurb section-2">
        <div className="subheading green-text secondary-text">CORPORATE</div>
        <div className="h1 primary-text">
          We have delivered to multinational
        </div>
        <div className="h1 primary-text">names such as Google, IBM, Nokia,</div>
        <div className="h1 primary-text">
          Siemens Networks, Phillips, Wipro,
        </div>
        <div className="h1 primary-text">
          amongst various others. You can be
        </div>
        <div className="h1 primary-text">
          rest assured we take great detail no
        </div>
        <div className="h1 primary-text">matter who the client is whether</div>
        <div className="h1 primary-text">
          small or large, local or international,
        </div>
        <div className="h1 primary-text">we remain rigid to our principle:</div>
        <div className="h1 primary-text">
          at EM we only initiate a relationship
        </div>
        <div className="h1 primary-text">for it to be forever sustained.</div>
        <div className="body-copy">
          <div className="secondary-text desktop-text">
            The workplace is where most people spend the majority of their days.
            We ensure with our designs for
          </div>
          <div className="secondary-text desktop-text">
            these spaces, you feel refreshed through your day, with the quality
            you feel satisfied with your surrounding
          </div>
          <div className="secondary-text desktop-text">
            environment and with the ergonomics in the furniture that we supply,
            you are most comfortable.
          </div>

          <p className="mobile-text secondary-text">
            The workplace is where most people spend the majority of their days.
            We ensure with our designs for these spaces, you feel refreshed
            through your day, with the quality you feel satisfied with your
            surrounding environment and with the ergonomics in the furniture
            that we supply, you are most comfortable.
          </p>
        </div>
      </div>

      <div
        className="section-3 snap-to-section corporate-img-wrapper"
        style={{
          paddingLeft: "16.66%",
          paddingRight: "16.66%",
          boxSizing: "border-box",
        }}
      >
        <Img
          src={"/corp-2.jpg"}
          className="corporate-img-lg"
          style={{ width: "100%", marginTop: -56 }}
          onLoad={() => {
            loadImage("1");
          }}
          loading={loading}
        />
      </div>

      <Footer />
    </Page>
  );
}

export default Corporate;
