import "../styles/Contact.css";
import ClickOutside from "./ClickOutside";
import { useAppState } from "../utils/appState";

function Contact() {
  const [, setAppState] = useAppState();
  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false, hideMenu: false });
      }}
    >
      {(wrapperRef) => (
        <div ref={wrapperRef} className="Contact">
          <div className="h3 heading">CONTACT</div>
          <div className="subheading brand">Essentially Metal</div>
          <div className="subheading address">
            No. 8 Andree Road, Shanthinagar, Bangalore 560 027
          </div>
          <div className="subheading email">
            Email: dhruv@essentiallymetal.com | nishu@essentiallymetal.com
          </div>
          <div className="subheading phone">Phone: +91 (0) 80 41127994</div>

          <div className="map">
            <a
              href="https://www.google.com/maps/place/Essentially+Metal/@12.960648,77.5920683,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae15da3b79fbbb:0x5932ac3b81391b3!8m2!3d12.960648!4d77.594257"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/map.jpg" alt={""} />
            </a>
          </div>
        </div>
      )}
    </ClickOutside>
  );
}

export default Contact;
