import { useEffect, useState, createRef, useRef } from "react";
import "../styles/Carousel.css";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
// import Carousel from "@brainhubeu/react-carousel";
// import "@brainhubeu/react-carousel/lib/style.css";

function Carousel({ images = [], interval }) {
  const [slides, setSlides] = useState(
    images.map((i) => ({ ...i, ref: createRef() }))
  );
  const [current, setCurrent] = useState(0);
  const [timeout, setTimeout] = useState(null);

  const getNext = (prev) => {
    const next = prev + 1;
    if (next > 50) {
      return 0;
    }

    if (slides.length - next < 4) {
      setSlides((prev) => {
        return [...prev, ...images.map((i) => ({ ...i, ref: createRef() }))];
      });
    }

    return next;
  };

  const play = (cb) => {
    setCurrent(getNext);
    cb();
  };

  const run = () => {
    setTimeout(
      window.setTimeout(() => {
        play(run);
      }, interval)
    );
  };

  const start = () => {
    console.log("Starting");
    if (!timeout) {
      play(run);
    }
  };

  const stop = () => {
    console.log("Stopping");
    if (timeout) {
      window.clearTimeout(timeout);
      setTimeout(null);
    }
  };

  useEffect(() => {
    start();
  }, []);

  const currentOffset = slides[current]?.ref?.current?.offsetLeft || 0;

  // console.log(current);
  // const [current, setCurrent] = useState(0);
  // const [offsets, setOffsets] = useState([]);
  // const [play, setPlay] = useState(false);
  // const scrollRef = useRef(null);
  // let imagesLoaded = [];
  // let widths = [];
  // let timeout = null;
  //
  // useEffect(() => {
  //   console.log("Running effect");
  //   if (play) {
  //     console.log("Playing");
  //     timeout = setTimeout(() => {
  //       setCurrent(current + 1);
  //     }, interval);
  //   }
  // }, [play, current]);
  //
  // const calculateOffsets = () => {
  //   console.log("Attempting offset calculation");
  //   console.log("Loaded:", imagesLoaded.length);
  //   if (imagesLoaded.length >= slides.length) {
  //     //calculate
  //     console.log("Calculating offsets");
  //     console.log("Widths:", widths);
  //     let sum = 0;
  //     const newOffsets = widths.map((w, i) => {
  //       const cachedSum = sum;
  //       sum += w;
  //       return cachedSum;
  //     });
  //     console.log(newOffsets);
  //     setOffsets(offsets);
  //     setPlay(true);
  //   } else {
  //     console.log("Cant calculate offsets");
  //     return;
  //   }
  // };
  //
  // const onLoad = (i) => {
  //   widths[i] = slides[i].ref.current.offsetWidth;
  //   imagesLoaded.push(i);
  //   calculateOffsets();
  // };

  // console.log(offsets);

  // const currentOffset = offsets[current];
  // console.log(offsets, currentOffset);

  return (
    <div
      className="carousel-wrapper-new"
      onMouseEnter={() => {
        stop();
      }}
      onMouseLeave={() => {
        start();
      }}
    >
      <div className="scroll-wrapper">
        <div
          className="slides-wrapper"
          style={{ transform: `translateX(-${currentOffset}px)` }}
        >
          {(slides || []).map((s, i) => (
            <div key={"slide " + i} className="slide" ref={s.ref}>
              <img
                src={s.url}
                onLoad={() => {
                  // console.log("Loaded");
                  // onLoad(i);
                }}
                alt={""}
                className={"show"}
              />
            </div>
          ))}
        </div>
      </div>
      <div
        className="left-arrow hover-pointer"
        onClick={() => {
          setCurrent((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              return prev;
            }
          });
        }}
      >
        <ArrowLeft />
      </div>
      <div
        className="right-arrow hover-pointer"
        onClick={() => {
          setCurrent(getNext);
        }}
      >
        <ArrowRight />
      </div>
    </div>
  );
}

export default Carousel;
