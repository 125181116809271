import "../styles/AboutUs.css";
import Footer from "../components/Footer";
import ShowOnScreen from "../components/ShowOnScreen";
import Page from "../components/Page";
import Link from "../components/Link";
// import Timeline from "../components/Timeline";
import TimelineNew from "../components/TimelineNew";
import TimelineVertical from "../components/TimelineVertical";
import useImageLoadState from "../utils/useImageLoadState";
import LoadingImages from "../components/LoadingImages";
import Img from "../components/Img";
import Gallery from "../components/Gallery";
import { useEffect } from "react";
import { useAppState } from "../utils/appState";

const articles = [
  { src: "/news-1.jpg", articles: ["/articles/article-1.jpg"] },
  { src: "/news-2.jpg", articles: ["/articles/article-2.jpg"] },
  { src: "/news-3.jpg", articles: ["/articles/article-3.jpg"] },
  { src: "/news-4.jpg", articles: ["/articles/article-4.jpg"] },
  { src: "/news-5.jpg", articles: ["/articles/article-5.jpg"] },
  { src: "/news-6.jpg", articles: ["/articles/article-6.jpg"] },
  { src: "/news-7.png", articles: ["/articles/article-7.jpg"] },
  { src: "/news-8.png", articles: ["/articles/article-8.jpg"] },
  {
    src: "/news-9.png",
    articles: ["/articles/article-9.jpg", "/articles/article-9.1.jpg"],
  },
  { src: "/news-10.jpg", articles: ["/articles/article-10.jpg"] },
  { src: "/news-11.png", articles: ["/articles/article-11.jpg"] },
  { src: "/news-12.jpg", articles: ["/articles/article-12.jpg"] },
  { src: "/news-13.png", articles: ["/articles/article-13"] },
  { src: "/news-14.png", articles: ["/articles/article-14.jpg"] },
];

function AboutUs() {
  const [loading, loadImage] = useImageLoadState(3);
  const [, setAppState] = useAppState();

  useEffect(() => {
    setAppState({ fixedHeader: true });

    return () => {
      setAppState({ fixedHeader: false });
    };
  }, [setAppState]);

  return (
    <Page className="AboutUs">
      {loading && <LoadingImages />}
      <div className="section section-1">
        <div className="primary-image">
          <Img
            src={"/about-1.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>
      <div className="section section-2">
        <div className="meta">
          <p className="body-copy secondary-text bc-1">
            Our Founder - Ravi Jouhari was a visionary. His imagination,
            creativity and foresight often pushed the boundaries within the
            realms of furniture design & manufacturing capabilities. Essentially
            Metal's products were simply examples of that visioncoming to life
            back in 1998.
          </p>
          <div className="primary-text">
            <div className="h1">We design, execute and</div>
            <div className="h1">pride ourselves on providing</div>
            <div className="h1">high quality, customised</div>
            <div className="h1">furniture solutions.</div>
          </div>

          <p className="body-copy secondary-text">
            Our products are composed of metal, wood, glass, upholstery, PVC
            wicker amongst many other types of innovative materials that allow
            you to flaunt the spaces.
          </p>
          <p className="body-copy secondary-text">
            Our in-house design and manufacturing teams enable Essentially Metal
            to offer a plethora of designs to our loyal and discerning customer
            base.
          </p>
        </div>

        <div className="image">
          <Img
            src={"/about-2.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>
      <div className="section section-3">
        <div className="h1 primary-text">The Essentially Metal Client List</div>
        <div className="subheading secondary-text">
          Our projects spread across various industries within the hospitality,
          corporate and
        </div>
        <div className="subheading secondary-text">domestic sectors.</div>

        <Link className="subheading link" to={"/clients"}>
          Our Client List
        </Link>

        <div className="primary-image">
          <Img
            src={"/about-3.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>
      <div className="section section-4 timeline-section">
        <div className="h1 primary-text">Timeline of our Journey</div>
        <ShowOnScreen className="timeline-wrapper hide-mobile">
          <TimelineNew />
        </ShowOnScreen>
        <TimelineVertical />
      </div>
      <div className="section section-5">
        <div className="h1 primary-text">News and Media</div>
        <div className="article-list">
          {articles.map((a) => (
            <div
              key={a.src}
              className="article hover-pointer"
              onClick={() => {
                setAppState({
                  modal: <Gallery images={a.articles} />,
                  hideMenu: true,
                });
              }}
            >
              <img src={a.src} alt={""} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Page>
  );
}

export default AboutUs;
