import { createRef, Fragment, useRef, useState } from "react";
// import TimelineItem from "./TimelineItem";
import FadeIn from "./FadeIn";
import { Spring } from "react-spring/renderprops";
import "../styles/TimelineNew.css";
import timelineItems from "../utils/timelineItems";

function Timeline() {
  const refsArray = timelineItems.map(() => createRef());
  const containerRef = useRef(null);
  const [reset, setReset] = useState(false);
  console.log(reset);
  return (
    <FadeIn className="timeline-container">
      <Spring
        from={{ percent: "0" }}
        to={{ percent: "1" }}
        config={{ duration: 20000 }}
        reset={reset}
        onStart={() => {
          setReset(false);
        }}
        onRest={() => {
          setReset(true);
        }}
      >
        {({ percent }) => (
          <Fragment>
            <div className="timeline-top">
              {timelineItems.map((t, i) => (
                <div key={t.year + "top"} className="timeline-item-container">
                  <div
                    className={
                      "timeline-desc " +
                      t.align +
                      " " +
                      (percent * (containerRef?.current?.offsetWidth || 0) >
                      (refsArray[i]?.current?.offsetLeft || 0)
                        ? "active"
                        : "")
                    }
                  >
                    {t.text.map((te, j) => (
                      <p key={"tt-" + j}>{te}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="timeline-middle">
              <div className="timeline">
                <div className="progress-container" ref={containerRef}>
                  <div
                    className="progress"
                    style={{ width: `${percent * 100}%` }}
                  />
                </div>

                {timelineItems.map((t, i) => (
                  <div
                    key={t.year}
                    className="timeline-item-container"
                    ref={refsArray[i]}
                  >
                    <div className="Timeline-item">
                      {/*<div className={`item-desc ${active ? "active" : ""} ${align}`}>
                      {text.map((t, i) => (
                        <p key={"tt-" + i}>{t}</p>
                      ))}
                    </div>*/}
                      <div className="item-placeholder">
                        <div className={"item-year " + t.align}>{t.year}</div>
                        <div
                          className={
                            "item-icon " +
                            (percent *
                              (containerRef?.current?.offsetWidth || 0) >
                            (refsArray[i]?.current?.offsetLeft || 0)
                              ? "active"
                              : "")
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="timeline-bottom">
              {timelineItems.map((t, i) => (
                <div
                  key={t.year + "bottom"}
                  className="timeline-item-container"
                >
                  <div
                    className={
                      "timeline-desc " +
                      t.align +
                      " " +
                      (percent * (containerRef?.current?.offsetWidth || 0) >
                      (refsArray[i]?.current?.offsetLeft || 0)
                        ? "active"
                        : "")
                    }
                  >
                    {t.text.map((te, j) => (
                      <p key={"tt-" + j}>{te}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </Spring>
    </FadeIn>
  );
}

export default Timeline;
