import { useRef, useEffect } from "react";
import { useAppState } from "../utils/appState";
import handleScroll from "../utils/handleScroll";

function Page({ className, children }) {
  const [, setAppState] = useAppState();
  const scrollRef = useRef(null);

  useEffect(() => {
    setAppState({ headerScroll: 0, footerScroll: 0 });
  }, [setAppState]);

  return (
    <div
      className={className}
      ref={scrollRef}
      onScroll={() => {
        handleScroll(scrollRef, setAppState);
      }}
    >
      {children}
    </div>
  );
}

export default Page;
