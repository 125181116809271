import { createRef, useRef } from "react";
import { Spring } from "react-spring/renderprops";
import timelineItems from "../utils/timelineItems";
import "../styles/TimelineVertical.css";

function TimelineItem({ year, text = [], container, wrapper, percent }) {
  const top = (wrapper && wrapper.current && wrapper.current.offsetTop) || 0;
  const total =
    (container && container.current && container.current.offsetHeight) || 0;

  const active = percent * total > top ? true : false;
  return (
    <div className="timeline-vertical-item">
      <div className={"icon " + (active ? "active" : "")} />
      <div className="meta">
        <div className="year">{year}</div>

        <div className="body-copy-small desc">{text}</div>
      </div>
    </div>
  );
}

function TimelineVertical() {
  const refsArray = timelineItems.map(() => createRef());
  const containerRef = useRef(null);

  return (
    <Spring
      from={{ percent: "0" }}
      to={{ percent: "1" }}
      config={{ duration: 20000 }}
    >
      {({ percent }) => (
        <div className="timeline-vertical">
          <div className="progress-bar" ref={containerRef}>
            <div className="progress" style={{ height: `${percent * 100}%` }} />
          </div>

          {timelineItems.map((t, i) => (
            <div
              key={t.year}
              className="timeline-item-container"
              ref={refsArray[i]}
            >
              <TimelineItem
                year={t.year}
                text={t.text}
                container={containerRef}
                wrapper={refsArray[i]}
                percent={percent}
              />
            </div>
          ))}
        </div>
      )}
    </Spring>
  );
}

export default TimelineVertical;
