// import "./Home.css";
import { useEffect, useState } from "react";
import { useAppState } from "../utils/appState";
import "../styles/Clients.css";

const clientList = {
  corporate: {
    logos: [
      "/corp-cl-1.png",
      "/corp-cl-2.png",
      "/corp-cl-3.png",
      "/corp-cl-4.png",
      "/corp-cl-5.png",
      "/corp-cl-6.png",
    ],
    items: [
      "ITC Infotech",
      "RSP Architects",
      "Jaisim Fountainhead",
      "McAfee",
      "ITPL",
      "Siemens",
      "Cos-B",
      "USI",
      "Trianz",
      "Timken",
      "TCS",
      "IBM",
      "TATA Steel",
      "Jindal Steel Works Ltd",
      "Calvin Klein",
      "CAP Gemini",
      "PSI data",
      "NDS",
      "Sony Wizcraft",
      "Triology",
      "Saboo Silks",
      "ING Vysya",
      "ANZ",
      "McCann Ericsson",
      "BAE Systems",
      "M-Phasis",
      "Symphony",
      "Clover Green",
      "Aviva",
      "Chowgule Group (Keltech Energies)",
      "Chemmanur Jewellers",
      "Tycoon International",
      "New Business Avenues",
      "Alufit",
      "BSI Group",
      "KPR Sugar Mills",
      "Art of Living Corporate Offices",
    ],
  },
  hospitality: {
    logos: [
      "/hosp-cl-1.png",
      "/hosp-cl-2.png",
      "/hosp-cl-3.png",
      "/hosp-cl-4.jpg",
      "/hosp-cl-5.png",
      "/hosp-cl-6.jpg",
    ],
    items: [
      "Taj Exotica, Goa",
      "Bangalore Club",
      "Royal Orchid Park Plaza",
      "Pizza Corner (I) Pvt Ltd",
      "Herbs & Spice, Bangalore",
      "Bangalore Golf Club",
      "Karnataka Golf Association",
      "Highgates Hotel",
      "Clovergreens Golf Club",
      "Bombay Post",
      "Beijing Bites",
      "The Lost Caravan",
      "Benjarong",
      "French Loaf",
      "10 Downing Street Sports Bar",
      "Bliss Chocolcate Lounge",
      "Mac Fast Food",
      "Dasaprakash",
      "Aangan Residency",
      "Jalsa Restaurant",
      "The Big Brewsky",
      "LebMex",
      "Tomatino Restaurant",
      "Pasta Street",
      "Le Cristaal",
    ],
  },
  educational: {
    logos: [
      "/edu-cl-1.png",
      "/edu-cl-2.png",
      "/edu-cl-3.jpg",
      "/edu-cl-4.png",
      "/edu-cl-5.png",
    ],
    items: [
      "Mallya Aditi International School",
      "Sindhi High School",
      "Deccan International School",
      "Sarla Birla Academy",
      "Bishop Cotton Boys School",
      "IFM Business School",
      "Achieve School",
      "Indian Institute of Astrophysics",
    ],
  },
  residential: {
    logos: [
      "/res-cl-1.png",
      "/res-cl-2.png",
      "/res-cl-3.png",
      "/res-cl-4.png",
      "/res-cl-5.png",
      "/res-cl-6.png",
    ],
    items: [
      "Morzaria Properties",
      "Continental Builders",
      "Skyline Construction Ltd",
      "Synergy Property Developers",
      "Indus Homes",
      "Reliable Builders",
      "Integrated Property Developers",
    ],
  },
  retail: {
    logos: [
      "/ret-cl-1.png",
      "/ret-cl-2.png",
      "/ret-cl-3.png",
      "/ret-cl-4.png",
      "/ret-cl-5.jpg",
    ],
    items: [
      "Mirrors and Within Parlour",
      "UB City",
      "Zara",
      "Tata International",
      "Marks and Spencers",
      "L'inoui Chocolates",
    ],
  },
};

function Clients() {
  const [, setAppState] = useAppState();
  const [selected, setSelected] = useState("corporate");
  const list = clientList[selected];
  const filters = [
    "corporate",
    "hospitality",
    "educational",
    "residential",
    "retail",
  ];
  useEffect(() => {
    setAppState({ fixedHeader: true });

    return () => {
      setAppState({ fixedHeader: false });
    };
  }, [setAppState]);

  return (
    <div className="Clients">
      <div className="section">
        <div className="h1">Our Clients.</div>
        <div className="list">
          <div className="filters">
            {filters.map((f, i) => (
              <div
                key={f}
                className={
                  "filter hover-pointer " + (selected === f ? "active" : "")
                }
                onClick={() => {
                  setSelected(f);
                }}
              >
                {f.toUpperCase()}
              </div>
            ))}
          </div>

          <div className="body">
            <div className="logos">
              {list.logos.map((l, j) => (
                <div className="logo">
                  <img
                    src={l}
                    style={
                      selected === "retail" && j === 0 ? { width: "50%" } : {}
                    }
                    alt={""}
                  />
                </div>
              ))}
            </div>

            <div className="items">
              {list.items.map((i) => (
                <div className="item body-copy-small">{i}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
