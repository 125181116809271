import { useHistory } from "react-router-dom";

function Link({ to, children, className }) {
  const history = useHistory();
  return (
    <div
      className={className + " hover-pointer"}
      onClick={() => {
        // setAppState({ headerScroll: 0, footerScroll: 0 });
        history.push(to);
      }}
    >
      {children}
    </div>
  );
}

export default Link;
