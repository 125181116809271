import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./screens/Home";
import AboutUs from "./screens/AboutUs";
import Corporate from "./screens/Corporate";
import Residential from "./screens/Residential";
import Educational from "./screens/Educational";
import Hospitality from "./screens/Hospitality";
import Portfolio from "./screens/Portfolio";
import Clients from "./screens/Clients";
import Menu from "./components/Menu";
import Link from "./components/Link";

import EnquireButton from "./components/EnquireButton";
import EnquiryForm from "./components/EnquiryForm";
import { useAppState } from "./utils/appState";

function App() {
  const [
    { modal, headerScroll, footerScroll, fixedHeader },
    setAppState,
  ] = useAppState();

  return (
    <div className="App">
      <header
        className="App-header"
        style={{
          height: fixedHeader ? 64 : 88 - 24 * headerScroll,
          background: `rgba(0,0,0,${fixedHeader ? 1 : headerScroll})`,
        }}
      >
        <Link className="App-logo" to="/">
          <img src={"/logo.png"} alt={""} />
        </Link>
      </header>

      <main className="App-main">
        <Switch>
          <Route path="/clients">
            <Clients />
          </Route>
          <Route path="/hospitality">
            <Hospitality />
          </Route>
          <Route path="/educational">
            <Educational />
          </Route>
          <Route path="/residential">
            <Residential />
          </Route>
          <Route path="/corporate">
            <Corporate />
          </Route>
          <Route path="/about">
            <AboutUs />
          </Route>

          <Route path="/portfolio">
            <Portfolio />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>

        <Menu style={{ marginBottom: footerScroll }} />

        <EnquireButton
          className={"enquire-button"}
          onClick={() => {
            setAppState({ modal: <EnquiryForm />, hideMenu: true });
          }}
          style={{ marginBottom: footerScroll }}
        />
      </main>

      {modal && <div className="App-modal">{modal}</div>}
    </div>
  );
}

export default App;
