// import "./Home.css";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import Page from "../components/Page";
import useImageLoadState from "../utils/useImageLoadState";
import LoadingImages from "../components/LoadingImages";
import Img from "../components/Img";
import { useEffect } from "react";
import { useAppState } from "../utils/appState";

function Residential() {
  const [loading, loadImage] = useImageLoadState(2);
  const [, setAppState] = useAppState();

  useEffect(() => {
    setAppState({ fixedHeader: true });

    return () => {
      setAppState({ fixedHeader: false });
    };
  }, [setAppState]);

  return (
    <Page className="Category Corporate">
      {loading && <LoadingImages />}
      <div className="section section-1">
        <div className="primary-image">
          <Img
            src={"/res-1.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>

      <div className="blurb section-2">
        <div className="subheading green-text secondary-text">DOMESTIC</div>
        <div className="h1 primary-text">
          Most often you're attracted to and
        </div>
        <div className="h1 primary-text">infatuated with good design.</div>
        <div className="h1 primary-text">
          Customisation of furniture ensures
        </div>
        <div className="h1 primary-text">you have an emotional connect</div>
        <div className="h1 primary-text">with the piece where you are most</div>
        <div className="h1 primary-text">at peace - HOME.</div>
        <div className="body-copy">
          <div className="secondary-text desktop-text">
            We have delivered countless pieces of customised furniture to our
            thousands of customers over the last
          </div>
          <div className="secondary-text desktop-text">
            two & a half decades. An impressive majority of customers only come
            back to us for all the furniture
          </div>
          <div className="secondary-text desktop-text">
            requirements. Staying true to our principle: we only initiate a
            relationship for it to be forever sustained.
          </div>
          <p className="mobile-text secondary-text">
            We have delivered countless pieces of customised furniture to our
            thousands of customers over the last two & a half decades. An
            impressive majority of customers only come back to us for all the
            furniture requirements. Staying true to our principle: we only
            initiate a relationship for it to be forever sustained.
          </p>
        </div>
      </div>

      <div className="section section-3">
        <div className="primary-image">
          <Img
            src={"/res-2.jpg"}
            onLoad={() => {
              loadImage("1");
            }}
            loading={loading}
          />
        </div>
      </div>

      <div className="section carousel-section">
        <Carousel
          images={[
            { url: "/res-c-1.jpg" },
            { url: "/res-c-2.jpg" },
            { url: "/res-c-3.jpg" },
            { url: "/res-c-4.jpg" },
          ]}
          interval={4000}
        />
      </div>

      <Footer />
    </Page>
  );
}

export default Residential;
