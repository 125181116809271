import { useState } from "react";
import "../styles/Footer.css";
import { ReactComponent as FacebookIcon } from "../icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../icons/instagram.svg";
import Link from "./Link";
import { useAppState } from "../utils/appState";
import Contact from "./Contact";
import Service from "./Service";
import useMutation from "../utils/useMutation";

function Footer() {
  const [, setAppState] = useAppState();
  const [email, setEmail] = useState("");
  const [subscribe] = useMutation(
    "https://sea-lion-app-zmtzv.ondigitalocean.app/api/subscribe",
    {
      onSuccess: (data) => {
        console.log("Response Data:", data);

        setAppState({
          modal: (
            <Service
              title={""}
              description={"Thank you for your subscription. Stay Tuned!"}
            />
          ),
          hideMenu: true,
        });
      },
      onError: (message) => {
        console.log("Error:", message);
      },
    }
  );

  return (
    <footer className="App-footer">
      <div className="meta">
        <div className="options">
          <Link to="/about" className="subheading option">
            What we do
          </Link>
          <div
            onClick={() => {
              setAppState({ modal: <Contact />, hideMenu: true });
            }}
            className="subheading option hover-pointer"
          >
            Contact
          </div>
        </div>
        <div className="social-links">
          <a
            className="social-link hover-pointer"
            href="https://www.facebook.com/essentiallymetal/"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            className="social-link hover-pointer"
            href="https://www.instagram.com/essentiallymetal/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </div>
        <div className="subheading masthead">
          Copyright Essentially Metal 2021. All rights Reserved.
        </div>
      </div>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            subscribe({ email });
          }}
          className="subscribe"
        >
          <div className="subheading desc">Subscribe to our Newsletter</div>
          <div className="text-input">
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <button type="submit" className="button">
            Subscribe
          </button>
        </form>
      </div>
    </footer>
  );
}

export default Footer;
